html, body {
  background-color: #79ADDC;
  height: 100%;
  margin: 0;
}

.ROOT-CSS {
  height: 600;
  width: 400;
}

body::backdrop {
  background-color: #79ADDC;
}

img {
  width: 80%;
  height: 100%;
}